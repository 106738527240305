import React, {Suspense} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {observer} from "mobx-react";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

/*
import IntroScreen from "./screens/intro/IntroScreen";
import LoginScreen from "./screens/login/LoginScreen";
import MainScreen from "./screens/_main/MainScreen";
import {MainViewModel} from "./screens/_main/MainViewModel";
import {_CatalogViewModel} from "./screens/catalog/_CatalogViewModel";
import {OffersViewModel} from "./screens/offer/list/OffersViewModel";
import {ProfileViewModel} from "./screens/profile_section_link/ProfileViewModel";
import {AppEvent, _AppViewModel} from "./_AppViewModel";
import {reaction} from "mobx";
import {ProductsViewModel} from './screens/products/list/ProductsViewModel'
import {CheckAuth} from "./data/utils/CheckAuth";
import {OfferFlowScreen} from "./screens/offer/details/OfferFlowScreen";
import {ProductFlowScreen} from "./screens/products/details/ProductFlowScreen";
import {FiltersFlowScreen} from "./screens/filter/FiltersFlowScreen";
import {StatisticViewModel} from "./screens/statistic/StatisticViewModel";
import {RangesScreen} from "./screens/statistic/range/RangesScreen";
import StatisticDetailsScreen from "./screens/statistic/details/StatisticDetailsScreen";
import TopScreen from "./screens/statistic/top/TopSellersScreen";
import RegisterFlowScreen from "./screens/_register/RegisterFlowScreen";
import ResetFlowScreen from "./screens/_reset/ResetFlowScreen";
import ChangePasswordScreen from "./screens/password/ChangePasswordScreen";
*/

import routes from "./routes";

const queryClient: QueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5
        }
    }
});

interface IRoute {
    path: string
    element: JSX.Element
    routes?: IRoute[]
}

const drawRoutes = (routes: IRoute[], parentPath: string = ``) => (
    <>
        {routes.map((route: IRoute) => (
            <Route key={`${parentPath}${route.path}`} path={`${parentPath}${route.path}`} element={route.element}>
                {!!route.routes && drawRoutes(route.routes, route.path)}
            </Route>
        ))}
    </>
);

const App = () => {
    /*const viewModel = useMemo(() => new _AppViewModel(), [])
    const mainViewModel = useMemo(() => new MainViewModel(), [])
    const catalogViewModel = useMemo(() => new _CatalogViewModel(), [])
    const offersViewModel = useMemo(() => new OffersViewModel(), [])
    const productsViewModel = useMemo(() => new ProductsViewModel(), [])
    const profileViewModel = useMemo(() => new ProfileViewModel(), [])
    const statisticViewModel = useMemo(() => new StatisticViewModel(), [])

    useEffect(
        () => reaction(() => viewModel.event,
            event => {
                if (event === AppEvent.Login || event === AppEvent.Logout) {
                    window.location.replace(window.location.origin)
                }
            }),
        []
    ) TODO вот реакция на логаут */

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Suspense fallback={null}>
                    <Routes>{drawRoutes(routes)}</Routes>
                </Suspense>

                {/*<Routes>
                    <Route path="/">
                        <Route index element={
                            <CheckAuth>
                                <MainScreen
                                    viewModel={mainViewModel}
                                    catalogViewModel={catalogViewModel}
                                    offersViewModel={offersViewModel}
                                    productsViewModel={productsViewModel}
                                    profileViewModel={profileViewModel}
                                    statisticViewModel={statisticViewModel}
                                />
                            </CheckAuth>
                        }/>
                        <Route path='filters/*' element={
                            <CheckAuth>
                                <FiltersFlowScreen productsViewModel={productsViewModel}/>
                            </CheckAuth>
                        }/>
                        <Route path="offers/:offerId/*" element={
                            <CheckAuth>
                                <OfferFlowScreen/>
                            </CheckAuth>
                        }/>
                        <Route path="products/:productId/*" element={
                            <CheckAuth>
                                <ProductFlowScreen/>
                            </CheckAuth>
                        }/>
                        <Route path="ranges" element={
                            <CheckAuth>
                                <RangesScreen viewModel={statisticViewModel}/>
                            </CheckAuth>
                        }/>
                        <Route path="top" element={
                            <CheckAuth>
                                <TopScreen/>
                            </CheckAuth>
                        }/>
                        <Route path="details/:offerId" element={
                            <CheckAuth>
                                <StatisticDetailsScreen/>
                            </CheckAuth>
                        }/>
                    </Route>
                </Routes>*/}
            </BrowserRouter>
            <ToastContainer />
        </QueryClientProvider>
    );
}

export {queryClient};
export default observer(App);
