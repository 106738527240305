import React, {lazy} from "react";
const LoginScreen = lazy(() => import(`./screens/login/LoginScreen`));
const RegisterScreen = lazy(() => import(`./screens/register/RegisterScreen`));
const ResetFlow = lazy(() => import(`./screens/reset/ResetFlow`));
const ResetRequestCodeScreen = lazy(() => import(`./screens/reset/ResetRequestCodeScreen`));
const ResetScreen = lazy(() => import(`./screens/reset/ResetScreen`));
const PasswordScreen = lazy(() => import(`./screens/password/ChangePasswordScreen`));
const CatalogScreen = lazy(() => import(`./screens/catalog/CatalogScreen`));
const OffersScreen = lazy(() => import(`./screens/offer/list/OffersScreen`));
const ProductsScreen = lazy(() => import(`./screens/products/list/ProductsScreen`));
const FiltersScreen = lazy(() => import(`./screens/filter/FiltersScreen`));
const PlatformsScreen = lazy(() => import(`./screens/filter/platforms/PlatformsScreen`));
const StatisticScreen = lazy(() => import(`./screens/statistic/StatisticScreen`));
const StatisticRangesScreen = lazy(() => import(`./screens/statistic/range/RangesScreen`));
const StatisticTopSellersScreen = lazy(() => import(`./screens/statistic/top/TopSellersScreen`));
const StatisticDetailsScreen = lazy(() => import(`./screens/statistic/details/StatisticDetailsScreen`));
const OfferDetailsScreen = lazy(() => import(`./screens/offer/details/OfferDetailsScreen`));
const OfferSellPromocodeScreen = lazy(() => import(`./screens/offer/order/sell/SellPromocodeScreen`));
const OfferPlainPromocodeScreen = lazy(() => import(`./screens/offer/order/plain/PlainPromocodeScreen`));
const ProductDetailsScreen = lazy(() => import(`./screens/products/details/ProductDetailsScreen`));
const OrderErrorScreen = lazy(() => import(`./screens/products/order/error/OrderErrorScreen`));
const ProductOrderScreen = lazy(() => import(`./screens/products/order/ProductOrderScreen`));
const ProductOrderSuccessScreen = lazy(() => import(`./screens/products/order/success/ProductOrderSuccessScreen`));
const ProductOrderFeedbackScreen = lazy(() => import(`./screens/products/order/feedback/ProductFeedbackScreen`));
const ProfileFlow = lazy(() => import(`./screens/profile/ProfileFlow`));
const ProfileCommonScreen = lazy(() => import(`./screens/profile/common/ProfileCommonScreen`));
const ProfilePersonalScreen = lazy(() => import(`./screens/profile/personal/ProfilePersonalScreen`));
const ProfileDocumentScreen = lazy(() => import(`./screens/profile/document/ProfileDocumentScreen`));
const ProfileDocumentAddressScreen = lazy(() => import(`./screens/profile/document_address/ProfileDocumentAddressScreen`));
const ProfileWithdrawalMethodsScreen = lazy(() => import(`./screens/profile/withdrawal_methods/ProfileWithdrawalMethodsScreen`));
const ProfileAddWithdrawalMethodScreen = lazy(() => import(`./screens/profile/add_withdrawal_method/ProfileAddWithdrawalMethodScreen`));
const ProfileAgreementsScreen = lazy(() => import(`./screens/profile/agreements/ProfileAgreementsScreen`));
const ProfileAgreementFileScreen = lazy(() => import(`./screens/profile/agreement_file/ProfileAgreementFileScreen`));
const ProfileChangePhoneScreen = lazy(() => import(`./screens/profile/change_phone/ProfileChangePhoneScreen`));
const ProfileChangePasswordScreen = lazy(() => import(`./screens/profile/change_password/ProfileChangePasswordScreen`));
const ProfilePayoutHistoryScreen = lazy(() => import(`./screens/profile/payout_history/ProfilePayoutHistoryScreen`));
const ProfilePayoutActScreen = lazy(() => import(`./screens/profile/payout_act/ProfilePayoutActScreen`));
const ProfilePayoutActFileScreen = lazy(() => import(`./screens/profile/payout_act_file/ProfilePayoutActFileScreen`));

const routes =  [
	{
		path: `/`,
		element: <CatalogScreen />,
		routes: [
			{
				path: ``,
				element: <OffersScreen />
			},
			{
				path: `/offers`,
				element: <OffersScreen />
			},
			{
				path: `/products`,
				element: <ProductsScreen />
			}
		]
	},
	{
		path: `/filters`,
		element: <FiltersScreen />
	},
	{
		path: `/filters/platforms`,
		element: <PlatformsScreen />
	},
	{
		path: `/statistics`,
		element: <StatisticScreen />
	},
	{
		path: `/statistics/ranges`,
		element: <StatisticRangesScreen />
	},
	{
		path: `/statistics/top`,
		element: <StatisticTopSellersScreen />
	},
	{
		path: `/statistics/details/:offerId`,
		element: <StatisticDetailsScreen />
	},
	{
		path: `/profile`,
		element: <ProfileFlow />,
		routes: [
			{
				path: ``,
				element: <ProfileCommonScreen />
			},
			{
				path: `/personal`,
				element: <ProfilePersonalScreen />
			},
			{
				path: `/document`,
				element: <ProfileDocumentScreen />
			},
			{
				path: `/document_address`,
				element: <ProfileDocumentAddressScreen />
			},
			{
				path: `/withdrawal_methods`,
				element: <ProfileWithdrawalMethodsScreen />
			},
			{
				path: `/add_withdrawal_method/:tab?`,
				element: <ProfileAddWithdrawalMethodScreen />
			},
			{
				path: `/agreements`,
				element: <ProfileAgreementsScreen />
			},
			{
				path: `/agreement`,
				element: <ProfileAgreementFileScreen />
			},
			{
				path: `/change_phone`,
				element: <ProfileChangePhoneScreen />
			},
			{
				path: `/change_password`,
				element: <ProfileChangePasswordScreen />
			},
			{
				path: `/payout_history`,
				element: <ProfilePayoutHistoryScreen />
			},
			{
				path: `/payout_act/:id/file`,
				element: <ProfilePayoutActFileScreen />
			},
			{
				path: `/payout_act/:id`,
				element: <ProfilePayoutActScreen />
			},
		]
	},
	{
		path: `/offers/:offerId`,
		element: <OfferDetailsScreen />
	},
	{
		path: `/offers/:offerId/promocode/:orderId`,
		element: <OfferSellPromocodeScreen />
	},
	{
		path: `/offers/:offerId/plain`,
		element: <OfferPlainPromocodeScreen />
	},
	/*{
		path: `/offers/:offerId/promocode_sell/:orderId`,
		element: <OfferDetailsScreen />
	},
	{
		path: `/offers/:offerId/promocode_share/:orderId`,
		element: <OfferDetailsScreen />
	},*/
	{
		path: `/products/:productId`,
		element: <ProductDetailsScreen />
	},
	{
		path: `/products/:productId/order/:orderId`,
		element: <ProductOrderScreen />
	},
	{
		path: `/products/:productId/order/:orderId/success`,
		element: <ProductOrderSuccessScreen />
	},
	{
		path: `/products/:productId/order/:orderId/feedback`,
		element: <ProductOrderFeedbackScreen />
	},
	{
		path: `/products/:productId/order/:orderId/error`,
		element: <OrderErrorScreen />
	},
	{
		path: `/login`,
		element: <LoginScreen />
	},
	{
		path: `/register`,
		element: <RegisterScreen />
	},
	{
		path: `/reset`,
		element: <ResetFlow />,
		routes: [
			{
				path: ``,
				element: <ResetRequestCodeScreen />
			},
			{
				path: `/code`,
				element: <ResetScreen />
			}
		]
	},
	{
		path: `/password`,
		element: <PasswordScreen />
	}
];
export default routes;